<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
       <policy-manager :username="username" :active="true"></policy-manager>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PolicyManager from '@/components/Cart/Manager/RefundPolicy/Manager'

export default {
  props: ['username'],
  components: {
    PolicyManager
  }
}
</script>

<style>

</style>
