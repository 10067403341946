<template>
  <v-card>
    <v-toolbar color="color1 color1Text--text" flat>
      <v-toolbar-title>Refund Policy Manager</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="color3Text color3--text" fab small @click.stop="newRefundPolicy">
        <v-icon small>fas fa-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="promoCodes"
      :options.sync="pagination"
      item-key="id"
      :loading="loading"
      :search="search"
      :show-select="doDelete"
      v-model="selected"
    >
      <template v-slot:progress>
        <v-progress-linear slot="progress" color="color3" indeterminate></v-progress-linear>
      </template>
      <template v-slot:[`item.default`]="{ item }">
        <v-icon v-if="item.default" class="success--text ml-2">fas fa-check-circle</v-icon>
      </template>
      <template v-slot:[`item.used`]="{ item }">
        <v-icon v-if="item.used" class="success--text ml-2">fas fa-check-circle</v-icon>
      </template>
      <template v-slot:[`item.text`]="{ item }">
        <div>
          {{item.text | trim50}}
          <v-btn
            class="mr-2"
            color="color3"
            x-small
            text
            @click="select(item)"
            v-if="item.text && item.text.length > 50"
          >more</v-btn>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          color="color3 color3Text--text"
          small fab
          @click="select(item)"
        >
          <v-icon small>fas fa-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-container class="pa-0">
      <v-expand-transition>
        <div v-if="selected.length">
          <v-row dense class="px-4">
            <v-col cols="12" class="subheading">
              What would you like to do with the selected policies?
            </v-col>
            <v-col cols="12">
              <v-btn
                color="color3 color3Text--text"
                class="mr-2"
                @click.stop="disableSelected"
              >Disable</v-btn>
              <v-btn
                color="error white--text"
                @click.stop="deleteSelected"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
      <v-row dense justify="end">
        <v-btn color="color3" text class="xsmall" icon @click.stop="asyncData" :loading="loading">
          <v-icon>fas fa-redo-alt</v-icon>
        </v-btn>
      </v-row>
    </v-container>
    <!-- DIALOG -->
    <refund-policy-details-drawer
      ref="detailsDrawer"
      :refundPolicy="selectedRefundPolicy"
      @editing-change="onEditingChange"
      :existing="policies"
      @save-complete="onSaveComplete"
      :username="username"
    ></refund-policy-details-drawer>
    <!-- Confirm-->
    <vbl-confirm-dialog
      :ask="confirmDialog"
      @confirm="() => { if(confirmAction) confirmAction() }"
      @deny="confirmDialog = false"
    ></vbl-confirm-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import RefundPolicy from '@/classes/RefundPolicy'
import RefundPolicyDetailsDrawer from './DetailsDrawer'

export default {
  props: ['active', 'username', 'existing'],
  data () {
    return {
      policies: [],
      loading: false,
      search: null,
      headers: [
        { text: 'Name', value: 'name', align: 'left' },
        { text: 'Is Default', value: 'default', align: 'left' },
        { text: 'In Use', value: 'used', align: 'left' },
        { text: 'Text', value: 'text', align: 'left' },
        { text: '', value: 'actions', align: 'center', sortable: false }
      ],
      persistDialog: false,
      selectedRefundPolicy: null,
      pagination: { itemsPerPage: 10 },
      selected: [],
      doDelete: true,
      confirmDialog: false,
      confirmAction: null,
      expanded: []
    }
  },
  computed: {
    ...mapGetters(['user', 'getPageInfo']),
    promoCodes () {
      return this.policies.map(m => new RefundPolicy(m))
    },
    organization () {
      return this.getPageInfo(this.username)
    }
  },
  methods: {
    select (promo) {
      this.selectedRefundPolicy = promo
      this.$refs.detailsDrawer.open()
    },
    asyncData () {
      this.loading = true
      this.$VBL.cart.refundPolicy.getAll(this.username)
        .then(r => { this.policies = r.data })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    onEditingChange (val) {
      this.persistDialog = val
    },
    newRefundPolicy () {
      this.select(new RefundPolicy({ organizationId: this.organization.id }))
    },
    onSaveComplete (dto) {
      if (dto.default) {
        this.asyncData()
      } else {
        var e = this.promoCodes.find(f => f.id === dto.id)
        if (e) {
          e.update(dto)
        } else {
          this.policies.push(dto)
        }
      }
      this.dialog = false
    },
    deleteSelected () {
      this.confirmAction = this.performDelete
      this.confirmDialog = true
    },
    disableSelected () {
      this.confirmAction = this.performDisable
      this.confirmDialog = true
    },
    performDisable () {
      this.loading = true
      const ids = this.selected.map(m => m.id)
      this.$VBL.post.disablePromos(this.username, ids)
        .then(r => {
          this.selected.forEach(f => {
            f.disabled = true
          })
          this.selected = []
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.confirmAction = null
          this.confirmDialog = false
        })
    },
    performDelete () {
      this.loading = true
      const ids = this.selected.map(m => m.id)
      this.$VBL.delete.policies(this.username, ids)
        .then(r => {
          this.policies = this.policies.filter(f => !ids.includes(f.id))
          this.selected = []
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.confirmAction = null
          this.confirmDialog = false
        })
    }
  },
  watch: {
    active: function (val) {
      if (val) {
        this.asyncData()
      }
    }
  },
  components: {
    RefundPolicyDetailsDrawer
  },
  mounted () {
    this.asyncData()
  }
}
</script>

<style>

</style>
