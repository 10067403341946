<template>
  <v-card :flat="flat" :class="cardClass" class="px-3">
    <v-row>
      <v-expand-transition>
        <v-col cols="12" v-if="!last">
          <v-select
            :label="dayLabel"
            :items="dayOptions"
            v-model="myRefundPolicy.uptoHoursOut"
            color="color3"
            item-color="color3"
            :disabled="!editing || (!!used && !vblOverride)"
            :hide-details="!errorMsg"
            :error-messages="errorMsg"
          ></v-select>
        </v-col>
      </v-expand-transition>
      <!-- AMOUNT TYPE -->
      <v-col cols="6">
        <v-select
          label="Type"
          :items="['Dollars', 'Percent']"
          v-model="refundType"
          :disabled="!editing || (!!used && !vblOverride)"
          hint="This can not be edited once it has been used"
          persistent-hint
          color="color3"
          item-color="color3"
        ></v-select>
      </v-col>
      <!-- AMOUNT -->
      <v-col cols="6">
        <v-text-field
          :label="amountLabel"
          v-model="refundValue"
          type="number"
          :disabled="!editing || (!!used && !vblOverride)"
          hint="This can not be edited once it has been used"
          persistent-hint
          @blur="$v.refundValue.$touch()"
          :error-messages="amountErrors"
          color="color3"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-row dense>
        <v-col cols="12">
          <v-window v-model="window">
            <v-window-item :key="0">
              <v-btn
                color="error"
                small text icon
                @click.stop="window = 1"
                :disabled="!editing || (!!used && !vblOverride)"
              >
                <v-icon small>fas fa-trash</v-icon>
              </v-btn>
            </v-window-item>
            <v-window-item :key="1">
              <div class="text-center">Delete this?</div>
              <div class="text-center">
                <v-btn color="success white--text" small class="mr-2" @click.stop="myRefundPolicy.deleted = true">
                  yes
                </v-btn>
                <v-btn color="error white--text" small @click.stop="window = 0">
                  no
                </v-btn>
              </div>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  props: ['myRefundPolicy', 'vblOverride', 'editing', 'flat', 'cardClass', 'used', 'last'],
  mixins: [validationMixin],
  validations: {
    refundValue: { required }
  },
  data () {
    return {
      iRefundType: null,
      iRefundValue: null,
      window: 0
    }
  },
  computed: {
    amountErrors () {
      const errors = []
      if (!this.$v.refundValue.$dirty) return errors
      !this.refundValue && errors.push('An amount is required')
      return errors
    },
    refundType: {
      get: function () {
        return this.myRefundPolicy.refundType
      },
      set: function (val) {
        this.iRefundType = val
        this.setRefund()
      }
    },
    refundValue: {
      get: function () {
        return this.myRefundPolicy && this.myRefundPolicy.refundValue
      },
      set: function (val) {
        this.iRefundValue = val
        this.setRefund()
      }
    },
    amountLabel () {
      return this.refundType === 'Percent' ? 'Percentage to refund' : 'Amount to keep'
    },
    dayOptions () {
      return [...Array(90).keys()].map(i => {
        return {
          text: `${i} Day${i !== 1 ? 's' : ''}`,
          value: (i) * 24
        }
      })
    },
    selectedDay () {
      return this.dayOptions.find(f => f.value === this.myRefundPolicy.uptoHoursOut)
    },
    dayLabel () {
      return this.myRefundPolicy && this.myRefundPolicy.uptoHoursOut ? `Up to ${this.selectedDay.text.toLowerCase()} before the event starts` : 'Up till the event refund cutoff'
    },
    errorMsg () {
      return this.last && this.myRefundPolicy.uptoHoursOut ? 'Because this is the last policy it will apply up until the event refund cutoff date' : ''
    }
  },
  methods: {
    setRefund () {
      if (this.iRefundType === 'Dollars') {
        this.myRefundPolicy.refundAmount = +this.iRefundValue
        this.myRefundPolicy.refundPercentage = null
      } else {
        this.myRefundPolicy.refundAmount = null
        this.myRefundPolicy.refundPercentage = +this.iRefundValue / 100
      }
    }
  }
}
</script>
