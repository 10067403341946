<template>
  <v-navigation-drawer
    right
    v-model="isOpen"
    fixed temporary
    :width="$vuetify.breakpoint.xs ? 350 : 500"
    :permanent="editing"
  >
    <!-- HEADER -->
    <template v-slot:prepend>
    <v-toolbar color="color2 color2Text--text" tile dense>
      <v-toolbar-title>Refund Policy Details</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="color1 color1Text--text"
        fab
        small
        @click.stop="cancel(true)"
      >
        <v-icon small>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    </template>
    <!-- FOOTER -->
    <template v-slot:append>
      <v-btn
        v-if="myRefundPolicy && !myRefundPolicy.isAdvanced"
        color="color3"
        text class="xsmall"
        @click.stop="advanced = !advanced"
      >
        {{advanced ? 'simple' : 'advanced'}}
      </v-btn>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row dense>
          <v-col cols="6">
            <v-btn
              :color="activeBtn.color"
              :disabled="activeBtn.disabled"
              :loading="activeBtn.loading"
              @click.stop="activeBtn.onClick"
              block
              class="py-9"
            >
              {{ activeBtn.text }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="error white--text"
              @click.stop="activeBtn.cancel"
              :disabled="activeBtn.loading"
              block
              class="py-9"
            >
            {{activeBtn.cancelText || 'Close'}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </template>
    <!-- BODY -->
    <v-card-text v-if="myRefundPolicy" :class="{ 'px-0': window === 1 }" :key="refreshKey">
      <v-container class="pt-10 px-0">
        <v-window v-model="window">
          <v-window-item :key="0">
            <v-container class="pt-0">
              <!-- Id & Name -->
              <v-row dense>
                <v-col cols="12" class="caption" v-if="!isNew">
                  Id: {{myRefundPolicy.id}} | Created: {{myRefundPolicy.dtCreated ? myRefundPolicy.dtCreated.format('LLLL') : 'NA'}} By: {{myRefundPolicy.createdBy}}
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Refund Policy Name*"
                    v-model="myRefundPolicy.name"
                    :disabled="!editing"
                    :error-messages="nameErrors"
                    @blur="$v.myRefundPolicy.name.$touch()"
                    persistent-hint
                    color="color3"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- 100% minus fees -->
              <v-row dense>
                <v-col cols="12">
                  <v-switch
                    label="100% Minus The Fees"
                    v-model="myRefundPolicy.allButFees"
                    :disabled="!editing || (!!myRefundPolicy.used && !vblOverride)"
                    color="success"
                    hint="This can not be edited once it has been used"
                    persistent-hint
                  ></v-switch>
                </v-col>
              </v-row>
              <!-- NOT 100% minus fees -->
              <v-expand-transition>
                <details-card v-if="!myRefundPolicy.allButFees"
                  :myRefundPolicy="myRefundPolicy"
                  :editing="editing"
                  :vblOverride="vblOverride"
                  :flat="myRefundPolicy.children.length === 0"
                  :used="myRefundPolicy.used"
                  :last="myRefundPolicy.children.length === 0"
                >
                </details-card>
              </v-expand-transition>
              <v-expand-transition v-for="(c, i) in myRefundPolicy.children" :key="`${c.id}-${i}`">
                <details-card v-if="!myRefundPolicy.allButFees"
                  :key="`${c.id}-${i}-card`"
                  :myRefundPolicy="c"
                  :editing="editing"
                  :vblOverride="vblOverride"
                  :flat="false"
                  :used="myRefundPolicy.used"
                  cardClass="mt-3"
                  :last="i === myRefundPolicy.children.length - 1"
                >
                </details-card>
              </v-expand-transition>
              <v-expand-transition>
                <v-row dense class="text-center" v-if="editing">
                  <v-btn
                    color="color3"
                    text small
                    @click.stop="addChild"
                    :disabled="!editing || (!!myRefundPolicy.used && !vblOverride)"
                  >
                    Add a step down
                  </v-btn>
                </v-row>
              </v-expand-transition>
              <!-- AMOUNT TYPE Notification -->
              <v-row>
                <v-col cols="12">
                  <v-alert type="info" :value="true" text>
                    <div v-for="(n, i) in notificationText" :key="i" :class="n.class">{{ n.text }}</div>
                  </v-alert>
                </v-col>
              </v-row>
              <!-- defaul checkbox -->
              <v-row>
                <v-col cols="6">
                  <v-checkbox
                    label="Set as default"
                    v-model="myRefundPolicy.default"
                    :disabled="!editing"
                    color="success"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" v-if="user && user.vbl">
                  <v-checkbox
                    label="Refund VBL Fee"
                    v-model="myRefundPolicy.refundAppFee"
                    :disabled="!editing"
                    color="success"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
              <!-- policy text & VBL Override -->
              <v-row dense>
                <v-col cols="12">
                  <v-textarea
                    :error-messages="textErrors"
                    @blur="$v.myRefundPolicy.text.$touch(); $v.refundValue.$touch()"
                    label="Policy Text*"
                    :disabled="!editing || (!!myRefundPolicy.used && !vblOverride)"
                    v-model="myRefundPolicy.text"
                    hint="This can not be edited once it has been used"
                    persistent-hint outlined
                  ></v-textarea>
                </v-col>
                <v-expand-transition>
                  <v-col cols="12" v-if="editing && user && user.vbl">
                    <v-switch
                      label="VBL Override"
                      v-model="vblOverride"
                      color="success"
                    ></v-switch>
                  </v-col>
                </v-expand-transition>
              </v-row>
              <v-expand-transition>
                <div v-if="error">
                  <v-alert type="error" :value="true">
                    Oops: {{error}}
                  </v-alert>
                </div>
              </v-expand-transition>
            </v-container>
          </v-window-item>
        </v-window>
      </v-container>
    </v-card-text>

  </v-navigation-drawer>
</template>

<script>
import RefundPolicy from '@/classes/RefundPolicy'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import DetailsCard from './DetailsCard'

export default {
  mixins: [validationMixin],
  props: ['refundPolicy', 'active', 'existing', 'username'],
  data () {
    return {
      isOpen: false,
      editing: false,
      iRefundPolicy: {},
      fab: null,
      saving: false,
      iRefundValue: null,
      iRefundType: null,
      advanced: false,
      error: null,
      window: 0,
      loading: false,
      vblOverride: false,
      refreshKey: 0
    }
  },
  validations: {
    myRefundPolicy: {
      name: { required },
      text: { required }
    },
    refundValue: { required }
  },
  computed: {
    ...mapGetters(['user']),
    nameErrors () {
      const errors = []
      if (!this.$v.myRefundPolicy.name.$dirty) return errors
      !this.$v.myRefundPolicy.name.required && errors.push('A name is required')
      this.taken && errors.push('You cannot have to refund policies with the same name')
      return errors
    },
    textErrors () {
      const errors = []
      if (!this.$v.myRefundPolicy.text.$dirty) return errors
      !this.$v.myRefundPolicy.text.required && errors.push('Policy text is required')
      return errors
    },
    taken () {
      return this.iRefundPolicy.name !== this.refundPolicy.name && this.existing && this.existing.filter(f => f && f.id !== this.myRefundPolicy.id).map(m => m.name).includes(this.myRefundPolicy.name)
    },
    isNew () {
      return this.refundPolicy && !this.refundPolicy.id
    },
    myRefundPolicy () {
      return this.editing ? this.iRefundPolicy : this.refundPolicy
    },
    refundValue () {
      return this.myRefundPolicy && this.myRefundPolicy.refundValue
    },
    notificationText () {
      const base = [{ text: 'Refund Amount:', class: 'text-h6' }]
      if (this.myRefundPolicy.allButFees) {
        base.push({ text: '100% minus all fees until the event refund cutoff' })
      } else {
        const m = this.myRefundPolicy
        let t = m.refundType === 'Dollars' ? `100% minus $${m.refundValue}.` : `${m.refundValue}%`
        let l = m.uptoHoursOut && m.children.length > 0 ? `up to ${m.uptoHoursOut / 24} days before the event starts` : 'up till the event refund cutoff'
        base.push({ text: t + ' ' + l })
        if (this.myRefundPolicy.children.length > 0) {
          base.push(...m.children.map((p, i) => {
            t = p.refundType === 'Dollars' ? `100% minus $${p.refundValue}.` : `${p.refundValue}%`
            l = p.uptoHoursOut && i < m.children.length - 1 ? `up to ${p.uptoHoursOut / 24} days before the event starts` : 'up till the event refund cutoff'
            return { text: t + ' ' + l }
          }))
        }
      }
      return base
    },
    activeBtn () {
      return !this.editing ? {
        color: 'color3Text color3--text',
        icon: 'fas fa-pencil-alt',
        onClick: this.edit,
        text: 'Edit',
        cancel: () => {
          this.cancel(true)
        },
        loading: this.loading || this.saving
      } : {
        color: 'success white--text',
        icon: 'fas fa-times',
        onClick: this.onSaveClick,
        text: 'save',
        cancelText: 'Cancel',
        cancel: this.cancel,
        disabled: !this.dirty || !this.valid,
        loading: this.saving
      }
    },
    dirty () {
      return JSON.stringify(this.refundPolicy) !== JSON.stringify(this.iRefundPolicy)
    },
    valid () {
      return this.myRefundPolicy.name && this.myRefundPolicy.text && (this.refundValue || this.myRefundPolicy.allButFees) && !this.taken
    },
    dto () {
      return this.user && this.user.vbl ? this.myRefundPolicy.vblDto : this.myRefundPolicy.dto
    }
  },
  methods: {
    onSaveClick () {
      this.error = null
      this.$v.$touch()
      if (!this.valid) return
      this.saving = true
      console.log(this.dto)
      this.$VBL.cart.refundPolicy.post(this.username, this.dto)
        .then(r => {
          this.$emit('save-complete', this.myRefundPolicy)
          this.cancel()
        })
        .catch(e => {
          console.log(e.response)
          this.error = e.response.data
        })
        .finally(() => { this.saving = false })
    },
    edit () {
      this.iRefundPolicy = new RefundPolicy(JSON.parse(JSON.stringify(this.refundPolicy)))
      this.iRefundValue = this.iRefundPolicy.refundValue
      this.iRefundType = this.iRefundPolicy.refundType
      this.addRefund = false
      this.editing = true
    },
    cancel (close) {
      this.editing = false
      this.iRefundPolicy = new RefundPolicy()
      this.$v.$reset()
      this.refreshKey++
      if (this.isNew || close === true) this.isOpen = false
    },
    setRefund () {
      if (this.iRefundType === 'Dollars') {
        this.iRefundPolicy.refundAmount = +this.iRefundValue
        this.iRefundPolicy.refundPercentage = null
      } else {
        this.iRefundPolicy.refundAmount = null
        this.iRefundPolicy.refundPercentage = +this.iRefundValue / 100
      }
    },
    open () {
      this.isOpen = true
    },
    addChild () {
      this.iRefundPolicy.childrenPolicies.push(new RefundPolicy({ organizationId: this.myRefundPolicy.organizationId, parentId: this.myRefundPolicy.id }))
    }
  },
  watch: {
    isOpen: function (val) {
      if (!val) {
        this.cancel()
      } else {
        this.isNew && this.edit()
        if (this.myRefundPolicy && this.myRefundPolicy.isAdvanced) this.advanced = true
      }
    },
    editing: function (val) {
      this.$emit('editing-change', val)
    }
  },
  mounted () {
    this.isNew && this.edit()
  },
  components: {
    DetailsCard
  }
}
</script>
