import moment from 'moment'
import DtoUpdate from './DtoUpdate'
import { firstBy } from 'thenby'

export default class RefundPolicy {
  id = 0
  organizationId = 0
  name = null
  refundAmount = null
  refundPercentage = null
  allButFees = false
  text = null
  refundAppFee = false
  deleted = false
  default = false
  used = false
  uptoHoursOut = null
  childrenPolicies = []
  parentId = null

  constructor (dto) {
    if (dto) this.update(dto)
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['childrenPolicies']
    DtoUpdate(dto, this, exclude)

    if (dto.childrenPolicies && dto.childrenPolicies.length > 0) {
      this.childrenPolicies = dto.childrenPolicies.map(m => new RefundPolicy(m))
    }

    if (this.childrenPolicies.length > 0) {
      this.childrenPolicies.sort(firstBy('uptoHoursOut', -1))
    }
  }

  // Getter Setters
  get dtCreated () {
    return moment(this._dtCreated)
  }

  set dtCreated (val) {
    this._dtCreated = val
  }

  // Getters
  get refundValue () {
    return this.refundAmount || Math.floor(this.refundPercentage * 100)
  }

  get refundType () {
    return this.refundAmount > 0 ? 'Dollars' : 'Percent'
  }

  get dto () {
    return !this.used ? this.vblDto : {
      id: this.id,
      organizationId: this.organizationId,
      name: this.name,
      deleted: this.deleted,
      default: this.default
    }
  }

  get vblDto () {
    return {
      id: this.id,
      organizationId: this.organizationId,
      name: this.name,
      refundAmount: this.refundAmount,
      refundPercentage: this.refundPercentage,
      allButFees: this.allButFees,
      text: this.text,
      refundAppFee: this.refundAppFee,
      deleted: this.deleted,
      default: this.default,
      uptoHoursOut: this.uptoHoursOut,
      childrenPolicies: this.childrenPolicies.filter(f => f.id || !f.deleted).map(m => m.dto),
      parentId: this.parentId
    }
  }

  get isAdvanced () {
    return !!this.uptoHoursOut || this.childrenPolicies.length > 0
  }

  get children () {
    return this.childrenPolicies.filter(f => !f.deleted)
  }
}
